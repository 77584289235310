import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Input,
  Select,
  Switch,
  Collapse,
  Button,
  message,
  Space,
  InputNumber,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { actionSourceNestedItemEdit, actionSourceSave } from '../actions';
import { AkukoAPIService } from '../../../services/serviceClass';
import {
  SOURCES_API,
  TEMPORAL_SOURCES_WORKER_WEB_SOCKET_URL,
} from '../../../configs/env';
import { Dictionary } from '@onaio/utils';
import { SourceGeojsonInput } from '../settings/SourceGeojsonInput';
import { CheckCircleOutlined } from '@ant-design/icons';
import {
  Source,
  geometryGenerationProgressStatusTypes,
  geometryGenerationEndpointTypes,
  geometryTypes,
  FileSourceCreationJobStatusTypes,
  UrlSourceCreationJobStatusTypes,
  PMTilesInputTypes,
} from '../../../configs/component-types';
import { triggerAsycSourceOperation } from './SourceWebsocketsUpdatesHelpers';
import { TippecanoeOptionsTag } from './TippecanoeOptionsTag';
import {
  startAsycJobForCreatingGeometry,
  startAsycJobForUpdatingGeometry,
  createSourceGeometryFromPMTilesServer,
  createSourceGeometryFromPMTilesFile,
  updateSourceGeometryPropertiesVals,
} from '../helpers/helpers';
import { GeometryUpdateProgressSteps } from './GeometryUpdateProgressSteps';
import { FileSourceCreationProgressSteps } from './FileSourceCreationProgressSteps';
import { UrlSourceCreationProgressSteps } from './UrlSourceCreationProgressSteps';
const { Option } = Select;
const { Panel } = Collapse;
export interface GeometryFormProps {
  item: Dictionary;
  itemIndex: number;
  sourceActionSaveCreator?: (obj: Source) => void;
}

/** default component props */
const defaultProps = {
  sourceActionSaveCreator: actionSourceSave,
};

const GeometryForm: React.FC<GeometryFormProps> = (
  props: GeometryFormProps
) => {
  const { item, itemIndex } = props;
  const asyncTaskName = 'Geometry generation';
  const dispatch = useDispatch();
  const source = useSelector((store: Dictionary) => store.source);
  const [name, setName] = useState(item.name);
  const [type, setType] = useState(item.type);
  const [loading, setLoading] = useState(false);
  const [tippecanoeOptionsList, setTippecanoeOptionsList] = useState<string[]>(
    []
  );
  const [targetSource, setTargetSource] = useState<Dictionary>({});
  const { sourceActionSaveCreator } = props;
  const [progress, setProgress] = useState<
    | {
        stage: geometryGenerationProgressStatusTypes;
        data: Dictionary[];
        errors: Dictionary[];
      }
    | Dictionary
  >({});
  const [accept, setAccept] = useState('');
  const [formDataVal, setFormDataVal] = useState<FormData>();
  const [pmtilesFileSize, setPMTilesFileSize] = useState<number>();
  const [pmtilesInputType, setPMTilesInputType] = useState<string>();
  const [mvtTilesUrl, setTilePath] = useState<string>(
    item?.mvtTilesUrl as string
  );
  const [pmtilesLayerName, setPMTilesLayerName] = useState<string>(
    item?.layerName as string
  );
  const [pmtilesMaxZoom, setPMTilesMaxZoom] = useState<number | null>(
    item?.maxzoom as number
  );
  const [pmtilesMinZoom, setPMTilesMinZoom] = useState<number | null>(
    item?.minzoom as number
  );

  useEffect(() => {
    if (
      item.type === geometryTypes.PMTILES &&
      pmtilesInputType === PMTilesInputTypes.FILE
    ) {
      setAccept('.pmtiles');
    }
  }, [item.type, pmtilesInputType]);

  useEffect(() => {
    if (item.geometry !== undefined) {
      const sourceFetchService = new AkukoAPIService(SOURCES_API, `source`);
      sourceFetchService.read(item.geometry).then((data) => {
        const res = data as Dictionary;
        setTargetSource(res);
      });
    }
  }, [item.geometry]);

  useEffect(() => {
    const tippecanoeOptionsPresent =
      source?.geometries !== undefined &&
      source?.geometries.find(
        (geometryObj: Dictionary) => geometryObj?.id === item.id
      )?.tippecanoe_options;
    if (tippecanoeOptionsPresent) {
      setTippecanoeOptionsList(tippecanoeOptionsPresent);
    }
  }, [item.id, source?.geometries]);

  const buildGeometryQueryPropertiesAndEndpointName = ({
    type,
    properties,
  }: {
    type: string;
    properties: string[] | undefined;
  }): {
    geometryPropertiesForCubeQuery: string[];
    endpointName: string;
  } => {
    let endpointName = '';
    const geometryPropertiesForCubeQuery =
      properties !== undefined ? [...properties] : [];
    // include source join if not added by user
    if (type === geometryTypes.JOIN) {
      endpointName = geometryGenerationEndpointTypes.JOIN;
      if (!geometryPropertiesForCubeQuery.includes(item.sourceJoin)) {
        geometryPropertiesForCubeQuery.push(item.sourceJoin);
      }
    }
    // include lat lng if not added by user
    if (type === geometryTypes.LATLONG) {
      endpointName = geometryGenerationEndpointTypes.LATLONG;
      if (!geometryPropertiesForCubeQuery.includes(item.lat)) {
        geometryPropertiesForCubeQuery.push(item.lat);
      }
      if (!geometryPropertiesForCubeQuery.includes(item.lng)) {
        geometryPropertiesForCubeQuery.push(item.lng);
      }
    }
    if (type === geometryTypes.COLUMN) {
      endpointName = geometryGenerationEndpointTypes.COLUMN;
      if (!geometryPropertiesForCubeQuery.includes(item.sourceColumn)) {
        geometryPropertiesForCubeQuery.push(item.sourceColumn);
      }
    }
    if (type === geometryTypes.SOURCE) {
      endpointName = `${source?.type}/${geometryGenerationEndpointTypes.SOURCE}`;
    }

    return {
      geometryPropertiesForCubeQuery,
      endpointName,
    };
  };

  const getSourceFromApiAndUpdateRedux = async () => {
    const sourceGetService = new AkukoAPIService(SOURCES_API, 'source');
    const res = await sourceGetService.read(`${source?.uuid}`);
    const sourceObj = res as Source;

    if (sourceActionSaveCreator) {
      dispatch(
        /* @ts-ignore */
        sourceActionSaveCreator({
          ...sourceObj,
        })
      );
    }
  };

  useEffect(() => {
    if (progress?.stage === geometryGenerationProgressStatusTypes.DONE) {
      if (progress?.data[0]?.geometry_id) {
        dispatch(
          actionSourceNestedItemEdit({
            itemIndex: itemIndex,
            parentProperty: 'geometries',
            property: 'id',
            value: progress?.data[0]?.geometry_id,
          })
        );
      }
      if (progress?.data[0]?.features_count) {
        dispatch(
          actionSourceNestedItemEdit({
            itemIndex: itemIndex,
            parentProperty: 'geometries',
            property: 'featureLength',
            value: progress?.data[0]?.features_count,
          })
        );
      }
      if (progress?.data[0]?.file_size_in_mb) {
        dispatch(
          actionSourceNestedItemEdit({
            itemIndex: itemIndex,
            parentProperty: 'geometries',
            property: 'fileSize',
            value: progress?.data[0]?.file_size_in_mb,
          })
        );
      }
      if (progress?.data[0]?.geometry?.pmtiles_generation_command) {
        dispatch(
          actionSourceNestedItemEdit({
            itemIndex: itemIndex,
            parentProperty: 'geometries',
            property: 'pmtiles_generation_command',
            value: progress?.data[0]?.geometry?.pmtiles_generation_command,
          })
        );
      }
      (async () => {
        await getSourceFromApiAndUpdateRedux();
      })();
      setLoading(false);
      setProgress({});
      message.success('Geometry saved');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  const handleGeometryGeneration = (geometry: Dictionary) => {
    setLoading(true);
    const { geometryPropertiesForCubeQuery, endpointName } =
      buildGeometryQueryPropertiesAndEndpointName({
        type: geometry?.type,
        properties: geometry?.properties,
      });
    let payload = {
      endpointName: endpointName,
      sourceId: source?.uuid,
      payload: {
        source_id: source?.uuid,
        tippecanoe_options: tippecanoeOptionsList,
      },
    } as Dictionary;

    if (type !== geometryTypes.SOURCE) {
      payload = {
        ...payload,
        payload: {
          ...payload?.payload,
          cube: source?.cube,
          dimensions: source?.dimensions,
          measures: source?.measures,
          cube_query_geometry_properties: geometryPropertiesForCubeQuery,
          geometry: geometry,
        },
      };
    }

    if (geometry?.id) {
      payload = {
        ...payload,
        geometryId: geometry?.id,
      };
    }

    triggerAsycSourceOperation({
      asynFunc: geometry?.id
        ? startAsycJobForUpdatingGeometry
        : startAsycJobForCreatingGeometry,
      asynFuncArgs: payload,
      asyncTaskName: asyncTaskName,
      setAsyncTaskProgressData: setProgress,
      setLoading: setLoading,
      webSocketUrl: TEMPORAL_SOURCES_WORKER_WEB_SOCKET_URL,
    });
  };

  const handleGeometryCreationFromPMTilesFile = (
    formData: FormData,
    fileSize: number
  ) => {
    setLoading(true);
    setProgress({
      stage: FileSourceCreationJobStatusTypes.UPLOADING,
      data: [
        {
          pending: [],
          done: [],
          percentage: 0,
        },
      ],
    });
    const onUploadProgress = (e: Dictionary) => {
      const calc = (e.loaded / Number(fileSize)) * 100;
      if (calc > 0 || calc <= 100) {
        setProgress({
          stage: FileSourceCreationJobStatusTypes.UPLOADING,
          data: [
            {
              pending: [],
              done: [],
              percentage: calc,
            },
          ],
        });
      }
      if (calc > 100) {
        setProgress({
          stage: FileSourceCreationJobStatusTypes.STARTING,
          data: [
            {
              pending: [],
              done: [],
            },
          ],
        });
      }
    };
    const payload = {
      name: item?.name,
      sourceId: source?.uuid,
      formData: formData,
      onUploadProgress: onUploadProgress,
    } as Dictionary;
    triggerAsycSourceOperation({
      asynFunc: createSourceGeometryFromPMTilesFile,
      asynFuncArgs: payload,
      asyncTaskName: 'Source Geometry Creation',
      setAsyncTaskProgressData: setProgress,
      setLoading: setLoading,
      webSocketUrl: TEMPORAL_SOURCES_WORKER_WEB_SOCKET_URL,
    });
  };

  const handleGeometryCreationFromMVTTilesUrl = () => {
    setLoading(true);
    setProgress({
      stage: UrlSourceCreationJobStatusTypes.STARTING,
      data: [
        {
          pending: [],
          done: [],
        },
      ],
    });
    const payload = {
      name: item?.name,
      sourceId: source?.uuid,
      mvtTilesUrl: mvtTilesUrl,
      layerName: pmtilesLayerName,
      maxZoom: pmtilesMaxZoom,
      minZoom: pmtilesMinZoom,
    } as Dictionary;
    triggerAsycSourceOperation({
      asynFunc: createSourceGeometryFromPMTilesServer,
      asynFuncArgs: payload,
      asyncTaskName: 'Source Geometry Creation',
      setAsyncTaskProgressData: setProgress,
      setLoading: setLoading,
      webSocketUrl: TEMPORAL_SOURCES_WORKER_WEB_SOCKET_URL,
    });
  };

  const handleGeometryUpdateFromMVTTilesUrl = async () => {
    setLoading(true);
    const payload = {
      ...item,
      name: item?.name,
      mvtTilesUrl: mvtTilesUrl,
      layerName: pmtilesLayerName,
      maxzoom: pmtilesMaxZoom,
      minzoom: pmtilesMinZoom,
    } as Dictionary;
    await updateSourceGeometryPropertiesVals(source?.uuid, payload);
    await getSourceFromApiAndUpdateRedux();
    setLoading(false);
    message.success('Geometry saved');
  };

  const disableFormInputsForFileTypePMTilesGeometryType =
    item?.id && item.type === geometryTypes.PMTILES && !item?.mvtTilesUrl;
  const editGeometryFromMVTUrl =
    item?.id && item.type === geometryTypes.PMTILES && item?.mvtTilesUrl;

  return (
    <>
      {loading && item.type !== geometryTypes.PMTILES && (
        <GeometryUpdateProgressSteps progress={progress} />
      )}
      {!editGeometryFromMVTUrl &&
        loading &&
        item.type === geometryTypes.PMTILES &&
        pmtilesInputType === PMTilesInputTypes.FILE && (
          <FileSourceCreationProgressSteps progress={progress} />
        )}
      {!editGeometryFromMVTUrl &&
        loading &&
        item.type === geometryTypes.PMTILES &&
        pmtilesInputType === PMTilesInputTypes.URL && (
          <UrlSourceCreationProgressSteps progress={progress} />
        )}
      {(editGeometryFromMVTUrl || !loading) && (
        <form>
          <Row gutter={10}>
            <Col xs={24} sm={24}>
              {item.id && (
                <p>
                  Id: {item.id}{' '}
                  <span className='success'>
                    <CheckCircleOutlined />
                  </span>
                </p>
              )}
              {item?.geometry_type && (
                <p>Geometry Type: {item?.geometry_type}</p>
              )}
              <div className='input-field'>
                <label>Name</label>
                <Input
                  value={name}
                  disabled={
                    source.geometries[itemIndex].type === 'source' ||
                    disableFormInputsForFileTypePMTilesGeometryType
                  }
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  onBlur={() => {
                    dispatch(
                      actionSourceNestedItemEdit({
                        itemIndex: itemIndex,
                        parentProperty: 'geometries',
                        property: 'name',
                        value: name,
                      })
                    );
                  }}
                />
              </div>
            </Col>
          </Row>
          {item.type !== geometryTypes.SOURCE && (
            <div>
              <Row gutter={10}>
                <Col xs={24} sm={24}>
                  <div className='input-field'>
                    <label>Geometry Type</label>
                    <Select
                      showSearch
                      value={type}
                      disabled={
                        disableFormInputsForFileTypePMTilesGeometryType ||
                        item?.mvtTilesUrl !== undefined
                      }
                      onChange={(value) => {
                        setType(value);
                      }}
                      onBlur={() => {
                        dispatch(
                          actionSourceNestedItemEdit({
                            itemIndex: itemIndex,
                            parentProperty: 'geometries',
                            property: 'type',
                            value: type,
                          })
                        );
                      }}
                    >
                      <Option value={`${geometryTypes.LATLONG}`}>
                        Latitude / Longitude
                      </Option>
                      <Option value={`${geometryTypes.JOIN}`}>Join</Option>
                      <Option value={`${geometryTypes.COLUMN}`}>Column</Option>
                      <Option value={`${geometryTypes.PMTILES}`}>
                        PMTiles
                      </Option>
                    </Select>
                  </div>
                </Col>
              </Row>
              {type === geometryTypes.COLUMN && (
                <Row gutter={10}>
                  <Col xs={24} sm={24}>
                    <label>Column</label>
                    <Select
                      value={item?.sourceColumn}
                      onChange={(value: string) => {
                        dispatch(
                          actionSourceNestedItemEdit({
                            itemIndex: itemIndex,
                            parentProperty: 'geometries',
                            property: 'sourceColumn',
                            value: value,
                          })
                        );
                      }}
                    >
                      {source?.dimensions?.map(
                        (item: Dictionary, index: number) =>
                          item.value && (
                            <Option key={`d-${index}`} value={item.value}>
                              {item.value}
                            </Option>
                          )
                      )}
                    </Select>
                  </Col>
                </Row>
              )}
              {!item?.id && type === geometryTypes.PMTILES && (
                <div className='pmtiles-geometry-input'>
                  <Row gutter={10}>
                    <Col xs={24} sm={24}>
                      <label>Input Type</label>
                      <Select
                        placeholder='Point to a hosted file or Upload file'
                        onChange={(value) => {
                          setPMTilesInputType(value);
                        }}
                      >
                        <Option value='url'>Hosted file</Option>
                        <Option value='file'>Upload file</Option>
                      </Select>
                    </Col>
                  </Row>
                </div>
              )}
              {!item?.id &&
                type === geometryTypes.PMTILES &&
                pmtilesInputType === PMTilesInputTypes.FILE && (
                  <div className='pmtiles-geometry-input'>
                    <Row gutter={10}>
                      <Col xs={24} sm={24}>
                        <label>Upload file</label>
                        <Input
                          type='file'
                          placeholder='Upload a PMTiles file'
                          disabled={loading}
                          accept={accept}
                          onChange={(e: Dictionary) => {
                            if (e?.target?.files.length === 0) {
                              setFormDataVal(undefined);
                              setPMTilesFileSize(undefined);
                            }
                            if (e?.target?.files.length !== 0) {
                              const fileSize = e.target.files[0].size;
                              const formData = new FormData();
                              formData.append('file', e.target.files[0]);
                              formData.append('fileType', source.type);
                              setFormDataVal(formData);
                              setPMTilesFileSize(fileSize);
                            }
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                )}
              {((!item?.id &&
                type === geometryTypes.PMTILES &&
                pmtilesInputType === PMTilesInputTypes.URL) ||
                (item?.id && item?.mvtTilesUrl)) && (
                <div className='pmtiles-geometry-input'>
                  <Row gutter={10}>
                    <Col xs={24} sm={24}>
                      <label>MVT Tiles Url</label>
                      <Input
                        value={mvtTilesUrl}
                        placeholder='MVT tiles root path e.g. https://example.com/tilename/{z}/{x}/{y}.mvt is https://example.com/tilename'
                        type='url'
                        disabled={loading}
                        onChange={(e) => {
                          setTilePath(e.target.value);
                        }}
                      />
                    </Col>
                    <Col xs={24} sm={24}>
                      <label>Layer name</label>
                      <Input
                        value={pmtilesLayerName}
                        placeholder='PMTiles layer name'
                        disabled={loading}
                        onChange={(e) => {
                          setPMTilesLayerName(e.target.value);
                        }}
                      />
                    </Col>
                    <Col xs={24} sm={24}>
                      <label>Max Zoom</label>
                      <InputNumber
                        min={0}
                        value={pmtilesMaxZoom}
                        placeholder='PMTiles max zoom'
                        disabled={loading}
                        onChange={(value) => {
                          setPMTilesMaxZoom(value);
                        }}
                      />
                    </Col>
                    <Col xs={24} sm={24}>
                      <label>Min Zoom</label>
                      <InputNumber
                        min={0}
                        value={pmtilesMinZoom}
                        placeholder='PMTiles min zoom'
                        disabled={loading}
                        onChange={(value) => {
                          setPMTilesMinZoom(value);
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              )}
              {type === geometryTypes.JOIN && (
                <Row gutter={10}>
                  <Col xs={24} sm={24}>
                    <SourceGeojsonInput
                      value={item.geometry}
                      onChange={(value: Dictionary) => {
                        dispatch(
                          actionSourceNestedItemEdit({
                            itemIndex: itemIndex,
                            parentProperty: 'geometries',
                            property: 'dimensions',
                            value: value.dimensions,
                          })
                        );
                        dispatch(
                          actionSourceNestedItemEdit({
                            itemIndex: itemIndex,
                            parentProperty: 'geometries',
                            property: 'geometry',
                            value: value.value,
                          })
                        );
                      }}
                    />
                  </Col>
                  <Col xs={24} sm={12}>
                    <div className='input-field'>
                      <label>Source Join</label>
                      <Select
                        showSearch
                        value={item.sourceJoin}
                        onChange={(value: string) => {
                          dispatch(
                            actionSourceNestedItemEdit({
                              itemIndex: itemIndex,
                              parentProperty: 'geometries',
                              property: 'sourceJoin',
                              value: value,
                            })
                          );
                        }}
                      >
                        {source?.dimensions?.map(
                          (item: Dictionary, index: number) =>
                            item.value && (
                              <Option key={`d-${index}`} value={item.value}>
                                {item.value}
                              </Option>
                            )
                        )}
                      </Select>
                    </div>
                  </Col>
                  <Col xs={24} sm={12}>
                    <div className='input-field'>
                      <label>Target Join</label>
                      <Select
                        showSearch
                        value={item.targetJoin}
                        onChange={(value: string) => {
                          dispatch(
                            actionSourceNestedItemEdit({
                              itemIndex: itemIndex,
                              parentProperty: 'geometries',
                              property: 'targetJoin',
                              value: value,
                            })
                          );
                        }}
                      >
                        {targetSource?.dimensions?.map(
                          (item: Dictionary, index: number) =>
                            item.value && (
                              <Option key={`d-${index}`} value={item.value}>
                                {item.value}
                              </Option>
                            )
                        )}
                      </Select>
                    </div>
                  </Col>
                </Row>
              )}
              {type === geometryTypes.LATLONG && (
                <Row gutter={10}>
                  <Col xs={24} sm={12}>
                    <div className='input-field'>
                      <label>Latitude</label>
                      <Select
                        showSearch
                        value={item.lat}
                        onChange={(value) => {
                          dispatch(
                            actionSourceNestedItemEdit({
                              itemIndex: itemIndex,
                              parentProperty: 'geometries',
                              property: 'lat',
                              value: value,
                            })
                          );
                        }}
                      >
                        {source?.dimensions?.map(
                          (item: Dictionary, index: number) =>
                            item.value && (
                              <Option key={index} value={item.value}>
                                {item.value}
                              </Option>
                            )
                        )}
                      </Select>
                    </div>
                  </Col>
                  <Col xs={24} sm={12}>
                    <div className='input-field'>
                      <label>Longitude</label>
                      <Select
                        showSearch
                        value={item.lng}
                        onChange={(value) => {
                          dispatch(
                            actionSourceNestedItemEdit({
                              itemIndex: itemIndex,
                              parentProperty: 'geometries',
                              property: 'lng',
                              value: value,
                            })
                          );
                        }}
                      >
                        {source?.dimensions?.map(
                          (item: Dictionary, index: number) =>
                            item.value && (
                              <Option key={index} value={item.value}>
                                {item.value}
                              </Option>
                            )
                        )}
                      </Select>
                    </div>
                  </Col>
                </Row>
              )}
              {((!item?.id && item.type !== geometryTypes.PMTILES) ||
                (item?.id &&
                  item?.properties &&
                  Object.keys(item?.properties).length > 0)) && (
                <Row>
                  <Col xs={24} sm={24}>
                    <div className='input-field'>
                      <label>Properties</label>
                      <Select
                        value={item.properties}
                        disabled={
                          disableFormInputsForFileTypePMTilesGeometryType
                        }
                        showSearch
                        mode={'multiple'}
                        onChange={(value) => {
                          dispatch(
                            actionSourceNestedItemEdit({
                              itemIndex: itemIndex,
                              parentProperty: 'geometries',
                              property: 'properties',
                              value: value,
                            })
                          );
                        }}
                      >
                        {source?.dimensions?.map(
                          (item: Dictionary, index: number) =>
                            item.value && (
                              <Option key={`d-${index}`} value={item.value}>
                                {item.value}
                              </Option>
                            )
                        )}
                        {source?.measures?.map(
                          (item: Dictionary, index: number) =>
                            item.name && (
                              <Option key={`m-${index}`} value={item.name}>
                                {item.name}
                              </Option>
                            )
                        )}
                        {targetSource?.dimensions?.map(
                          (item: Dictionary, index: number) =>
                            item.value && (
                              <Option key={`td-${index}`} value={item.value}>
                                {item.value}
                              </Option>
                            )
                        )}
                      </Select>
                    </div>
                  </Col>
                </Row>
              )}
            </div>
          )}
          {type !== geometryTypes.PMTILES &&
            item?.fileSize < 1000000 &&
            item?.featureLength < 6000 && (
              <Row>
                <Col xs={24} sm={24}>
                  <div className='geojson-field'>
                    <label>Render as GeoJSON</label>
                    <Switch
                      checked={item?.renderAsGeojson}
                      onChange={(value) => {
                        dispatch(
                          actionSourceNestedItemEdit({
                            itemIndex: itemIndex,
                            parentProperty: 'geometries',
                            property: 'renderAsGeojson',
                            value: value,
                          })
                        );
                      }}
                    />
                    <p>
                      Render this layer as a GeoJSON directly on the map instead
                      of using a tileset. This is useful if you wish to style
                      your layer using measures.
                    </p>
                  </div>
                </Col>
              </Row>
            )}
          {/* {item?.fileSize < 1000000 && item?.featureLength < 6000 && (
            <Row>
              <Col xs={24} sm={24}>
                <div className='geojson-field'>
                  <label>Derive Data from Feature state</label>
                  <Switch
                    checked={item?.featureState}
                    onChange={(value) => {
                      dispatch(
                        actionSourceNestedItemEdit({
                          itemIndex: itemIndex,
                          parentProperty: 'geometries',
                          property: 'featureState',
                          value: value,
                        })
                      );
                    }}
                  />
                  <p>
                    Select this option to utilize time-series filters or
                    measures as filters on your map layer. Note: This is
                    typically unnecessary for most cases. Avoid opting in unless
                    utilizing the above options, as it may slightly impact
                    performance.
                  </p>
                </div>
              </Col>
            </Row>
          )} */}
          {type !== geometryTypes.PMTILES && (
            <div className='advanced-settings'>
              <Collapse>
                <Panel header={'Advanced settings'} key={1}>
                  <>
                    {source.geometries[itemIndex].type !==
                      geometryTypes.SOURCE && (
                      <>
                        <Row gutter={10}>
                          <Col xs={24} sm={24}>
                            <p>
                              Properties can be grouped to enable filtering by
                              month, year, etc.
                            </p>
                            <div className='input-field'>
                              <label>Grouped fields</label>
                              <Select
                                value={source.geometries[itemIndex].groupBy}
                                showSearch
                                placeholder={'date'}
                                mode={'multiple'}
                                onChange={(values) => {
                                  dispatch(
                                    actionSourceNestedItemEdit({
                                      itemIndex: itemIndex,
                                      parentProperty: 'geometries',
                                      property: 'groupBy',
                                      value: values,
                                    })
                                  );
                                }}
                              >
                                {source?.dimensions?.map(
                                  (item: Dictionary, index: number) =>
                                    item.value && (
                                      <Option
                                        key={`d-${index}`}
                                        value={item.value}
                                      >
                                        {item.value}
                                      </Option>
                                    )
                                )}
                                {source?.measures?.map(
                                  (item: Dictionary, index: number) =>
                                    item.value && (
                                      <Option
                                        key={`m-${index}`}
                                        value={item.name}
                                      >
                                        {item.name}
                                      </Option>
                                    )
                                )}
                              </Select>
                            </div>
                          </Col>
                          <Col xs={24} sm={24}>
                            <div className='input-field'>
                              <label>Grouped values</label>
                              <Select
                                value={
                                  source.geometries[itemIndex].groupByValues
                                }
                                showSearch
                                placeholder={'total_clients'}
                                mode={'multiple'}
                                onChange={(values) => {
                                  dispatch(
                                    actionSourceNestedItemEdit({
                                      itemIndex: itemIndex,
                                      parentProperty: 'geometries',
                                      property: 'groupByValues',
                                      value: values,
                                    })
                                  );
                                }}
                              >
                                {source?.dimensions?.map(
                                  (item: Dictionary, index: number) =>
                                    item.value && (
                                      <Option
                                        key={`d-${index}`}
                                        value={item.value}
                                      >
                                        {item.value}
                                      </Option>
                                    )
                                )}
                                {source?.measures?.map(
                                  (item: Dictionary, index: number) =>
                                    item.value && (
                                      <Option
                                        key={`m-${index}`}
                                        value={item.name}
                                      >
                                        {item.name}
                                      </Option>
                                    )
                                )}
                              </Select>
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}
                    <Row>
                      <Col xs={24} sm={24}>
                        <div className='input-field'>
                          <label>Tile Generation</label>
                          <Space style={{ width: '100%' }} direction='vertical'>
                            <Row>
                              <Col xs={24}>
                                <TippecanoeOptionsTag
                                  tippecanoeOptionsList={tippecanoeOptionsList}
                                  setTippecanoeOptionsList={
                                    setTippecanoeOptionsList
                                  }
                                />
                              </Col>
                            </Row>
                          </Space>
                        </div>
                      </Col>
                    </Row>
                  </>
                </Panel>
              </Collapse>
            </div>
          )}
          {!disableFormInputsForFileTypePMTilesGeometryType && (
            <Button
              disabled={loading}
              type='primary'
              onClick={async () => {
                if (item?.type !== geometryTypes?.PMTILES) {
                  handleGeometryGeneration(item);
                }
                if (
                  item?.type === geometryTypes?.PMTILES &&
                  formDataVal !== undefined &&
                  pmtilesFileSize !== undefined &&
                  pmtilesInputType === PMTilesInputTypes.FILE
                ) {
                  handleGeometryCreationFromPMTilesFile(
                    formDataVal,
                    pmtilesFileSize
                  );
                }
                if (
                  item?.type === geometryTypes?.PMTILES &&
                  pmtilesInputType === PMTilesInputTypes.URL
                ) {
                  handleGeometryCreationFromMVTTilesUrl();
                }
                if (
                  item?.id &&
                  item.type === geometryTypes.PMTILES &&
                  item?.mvtTilesUrl
                ) {
                  await handleGeometryUpdateFromMVTTilesUrl();
                }
              }}
            >
              Save
            </Button>
          )}
        </form>
      )}
    </>
  );
};

GeometryForm.defaultProps = defaultProps;
export { GeometryForm };
