/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  TableOutlined,
  CopyOutlined,
  DeleteOutlined,
  PlusOutlined,
  EnvironmentOutlined,
  EyeOutlined,
  LayoutOutlined,
  DoubleLeftOutlined,
  AppstoreOutlined,
  NumberOutlined,
  FilterOutlined,
  UnorderedListOutlined,
  LineChartOutlined,
  MenuOutlined,
  PictureOutlined,
} from '@ant-design/icons';
import { Dictionary } from '@onaio/utils';
import { AddComponentMenu } from '../../../helpers/Settings/add-component-menu';
import {
  actionPostComponentOrderEdit,
  actionPostComponentMove,
  actionConfigComponentIndexActive,
  actionPostComponentSettingEdit,
  actionConfigDrawerOpenEdit,
  actionConfigActiveLayerEdit,
  actionConfigActiveColumnEdit,
  actionPostComponentCopy,
  actionPostArrayItemAdd,
  actionPostArrayItemDelete,
  actionPostComponentDelete,
  actionPostArrayItemMove,
} from '../../../actions';
import { makeId } from '../../../Map/helpers';
import { Tree, Dropdown } from 'antd';
import { resizeMaps } from '../../../Map/helpers';
import './style.css';
import { componentsMenuBuilder } from '../../../helpers/Settings/componentsMenuBuilder';

export interface ComponentMenuProps {
  hasError?: boolean;
}

const ComponentMenu = (): JSX.Element => {
  const dispatch = useDispatch();
  const post = useSelector((state: Dictionary) => state.post);
  const [treeData, setTreeData] = useState<Dictionary[]>([]);
  const [showRightClickMenu, setShowRightClickMenu] = useState(false);
  const [menuPosition, setMenuPosition] = useState([0, 0]);
  const [activeNode, setActiveNode] = useState({
    index: 0,
    columnIndex: 0,
    layerIndex: 0,
    type: null,
  });

  const addActiveComponentClass = (id: string) => {
    const activeComponent = document.getElementById(id);
    activeComponent?.classList.add('component-active');
  };

  const removeActiveComponentClass = (id: string) => {
    const activeComponent = document.getElementById(id);
    activeComponent?.classList.remove('component-active');
  };

  const scrollToActiveComponent = (id: string) => {
    const activeComponent = document.getElementById(id);
    activeComponent?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  };

  const getComponentIndexByLayoutId = (id: string) => {
    let componentIndex = 0;
    post.components.forEach((item: Dictionary, index: number) => {
      if (item.layout === id) {
        componentIndex = index;
      }
    });
    return componentIndex;
  };

  const getComponentByLayoutId = (id: string) => {
    let component = {};
    post.components.forEach((item: Dictionary) => {
      if (item.layout === id) {
        component = item;
      }
    });
    return component;
  };

  const getComponentIcon = (item: Dictionary) => {
    if (item.type === 'map' || item.type === 'layer') {
      return <EnvironmentOutlined />;
    }
    if (item.type === 'text') {
      return <MenuOutlined />;
    }
    if (item.type === 'layout') {
      return <LayoutOutlined />;
    }
    if (item.type === 'table') {
      return <TableOutlined />;
    }
    if (item.type === 'image') {
      return <PictureOutlined />;
    }
    if (item.type === 'number') {
      return <NumberOutlined />;
    }
    if (item.type === 'filter') {
      return <FilterOutlined />;
    }
    if (item.type === 'card') {
      return <AppstoreOutlined />;
    }
    if (item.type === 'button') {
      return <UnorderedListOutlined />;
    }
    if (item.type === 'chart') {
      return <LineChartOutlined />;
    }
    return <MenuOutlined />;
  };

  const isVisible = (item: Dictionary) => {
    let value = true;
    if (item.visible === undefined || item.hidden === undefined) {
      value = true;
    }
    if (item.visible === true || item.hidden === false) {
      value = true;
    }
    if (item.visible === false || item.hidden === true) {
      value = false;
    }
    return value;
  };

  const getTableColumns = (table: Dictionary, index: number) => {
    const array: Dictionary[] = [];
    table.properties.forEach((column: Dictionary, columnIndex: number) => {
      array.push({
        key: column.id,
        id: table.id,
        title: (
          <div style={{ color: isVisible(column) ? '#111' : '#aaa' }}>
            <TableOutlined />
            &nbsp;&nbsp; {column.propertyAlias || column.value || 'Column'}
            &nbsp;&nbsp;
          </div>
        ),
        type: 'column',
        index: index,
        columnIndex: columnIndex,
        children: column.contextFromRow
          ? [
              {
                type: 'layout',
                id: post.components[getComponentIndexByLayoutId(table.id)].id,
                index: getComponentIndexByLayoutId(table.id),
                title: (
                  <div>
                    <LayoutOutlined />
                    &nbsp;&nbsp; Column&nbsp;&nbsp;
                  </div>
                ),
                children: getLayoutChildren(getComponentByLayoutId(table.id)),
              },
            ]
          : [],
      });
    });
    array.push({
      key: `${index}-add`,
      columnIndex: array.length - 1,
      index: index,
      type: 'column',
      title: (
        <PlusOutlined
          onClick={() => {
            dispatch(
              actionPostArrayItemAdd({
                componentIndex: index,
                itemIndex: undefined,
                parents: undefined,
                property: 'properties',
                value: {
                  id: makeId(8),
                  visible: true,
                  colorScale: 'ckmeans',
                },
              })
            );
            setTimeout(() => {
              // set active column
              dispatch(
                actionConfigActiveColumnEdit({
                  value: array.length - 1,
                })
              );
              // set no active layer
              dispatch(
                actionConfigActiveLayerEdit({
                  value: -1,
                })
              );
            }, 100);
          }}
        />
      ),
    });
    return array;
  };

  const getMapLayers = (map: Dictionary, index: number) => {
    const array: Dictionary[] = [];
    map.layers.forEach((layer: Dictionary, layerIndex: number) => {
      array.push({
        key: `${map.id}-${layer.id}`,
        id: map.id,
        title: (
          <div style={{ color: isVisible(layer) ? '#111' : '#aaa' }}>
            <EnvironmentOutlined />
            &nbsp;&nbsp; {layer?.layerName || 'Layer'}&nbsp;&nbsp;
          </div>
        ),
        type: 'layer',
        index: index,
        layerIndex: layerIndex,
        children:
          layer.hasPopup && layer.contextFromPopup
            ? [
                {
                  type: 'layout',
                  index: getComponentIndexByLayoutId(map.id),
                  title: (
                    <div>
                      <LayoutOutlined />
                      &nbsp;&nbsp; Popup&nbsp;&nbsp;
                    </div>
                  ),
                  children: getLayoutChildren(getComponentByLayoutId(map.id)),
                },
              ]
            : [],
      });
    });
    array.push({
      key: `${index}-add`,
      layerIndex: array.length - 1,
      index: index,
      type: 'layer',
      title: (
        <PlusOutlined
          onClick={() => {
            dispatch(
              actionPostArrayItemAdd({
                componentIndex: index,
                itemIndex: undefined,
                parents: undefined,
                property: 'layers',
                value: {
                  id: makeId(8),
                  key: '',
                  layerName: '',
                  layerGroup: '',
                  source: '',
                  layerOpacity: 1,
                  fillColor: '#1890ff',
                  circleStrokeWidth: 1,
                  circleStrokeColor: '#FFFFFF',
                  circleStrokeOpacity: 0.9,
                  colorMethod: 'simple',
                  colorScale: 'ckmeans',
                  colorRange: 'OrRd',
                  visible: true,
                  icon: {
                    id: 'marker-15-custom',
                    src: 'https://s3.amazonaws.com/assets.akuko.io/maki-icons/marker-15.svg',
                    title: 'marker',
                  },
                  fillFieldStartColor: '#feb642',
                  fillFieldEndColor: '#7f0000',
                  iconColor: '#1890ff',
                  iconSize: 0.2,
                  iconAllowOverlap: true,
                  lineWidth: 1,
                  fillOutlineColor: '#ffffff',

                  circleRadius: 7,
                  radiusScale: 'simple',
                  legend: {
                    position: 'below',
                  },
                  maxCircleRadius: 20,
                  minCircleRadius: 2,
                  circleRadiusScale: 'ckmeans',
                  circleRadiusClass: 6,
                  categoricalClasses: 10,
                },
              })
            );
            setTimeout(() => {
              // set active layer
              dispatch(
                actionConfigActiveLayerEdit({
                  value: array.length - 1,
                })
              );
              // set no active column
              dispatch(
                actionConfigActiveColumnEdit({
                  value: -1,
                })
              );
            }, 100);
          }}
        />
      ),
    });
    return array;
  };

  const getLayoutIndex = (index: number, cols: number) => {
    const currentColVal = index / cols;
    const currentCol = String(currentColVal + 1).split('.')[0];
    const letters = ['a', 'b', 'c', 'd'];
    return `${currentCol}-${letters[index % cols]}`;
  };

  const getLayoutRegions = (layout: Dictionary) => {
    const array: Dictionary[] = [];
    let regionCols = 0;
    if (layout.layoutId === 'tabs' && layout.tabs.length > 0) {
      layout.tabs.forEach((item: Dictionary) => {
        array.push({
          title: '---',
          region: item.id,
          spaceComponent: layout.spaceComponent,
          key: item.id,
          layoutId: layout.id,
          type: 'region',
          children: [],
        });
      });
      return array;
    }
    if (layout.layoutId === 'collapse' && layout.collapse?.length > 0) {
      layout.collapse.forEach((item: Dictionary) => {
        array.push({
          title: '---',
          region: item.id,
          spaceComponent: layout.spaceComponent,
          key: item.id,
          layoutId: layout.id,
          type: 'region',
          children: [],
        });
      });
      return array;
    }
    if (layout?.layoutId) {
      regionCols = layout?.layoutId?.split('-').length;
    }
    if (layout?.cardLayout) {
      regionCols = layout?.cardLayout?.split('-').length;
    }
    if (regionCols > 0) {
      const regionCount = regionCols * layout.rows;
      for (let i = 0; i < regionCount; i++) {
        array.push({
          title: (
            <div className='add-component-wrapper'>
              <Dropdown
                overlay={componentsMenuBuilder({
                  dispatch: dispatch,
                  postType: post?.config?.type,
                  componentsLength: post?.components?.length,
                  layoutId: layout.id,
                  regionIndex: getLayoutIndex(i, regionCols) as any,
                })}
                trigger={['click']}
              >
                <div
                  id='btn-add-component'
                  className='btn-add'
                  onClick={(e) => {
                    e.preventDefault;
                  }}
                >
                  ...
                </div>
              </Dropdown>
            </div>
          ),
          region: getLayoutIndex(i, regionCols),
          spaceComponent: layout.spaceComponent,
          key: makeId(8),
          layoutId: layout.id,
          type: 'region',
          children: [],
        });
      }
    }
    return array;
  };

  const getLayoutChildren = (layout: Dictionary) => {
    const components: Dictionary[] = [];
    const regions = getLayoutRegions(layout);
    if (regions.length > 0) {
      post.components.forEach((item: Dictionary, index: number) => {
        if (item?.layout === layout.id) {
          if (item.type !== 'table' && item.type !== 'map') {
            components.push({
              key: item.id,
              id: item.id,
              title: (
                <div
                  className='tree-node-item'
                  id={`component-tree-${item.id}`}
                  style={{ color: isVisible(item) ? '#111' : '#aaa' }}
                >
                  {getComponentIcon(item)} &nbsp;&nbsp;
                  {item.name?.substring(0, 8) || item.type}
                  &nbsp;&nbsp;
                </div>
              ),
              index: index,
              spaceComponent: layout.spaceComponent,
              layout: item.layout,
              region: item.region,
              type: item.type,
              children:
                item.type === 'layout' || item.type === 'card'
                  ? getLayoutChildren(item)
                  : [],
            });
          }
          if (item.type === 'table') {
            components.push({
              key: item.id,
              id: item.id,
              title: (
                <div
                  className='tree-node-item'
                  id={`component-tree-${item.id}`}
                  style={{ color: isVisible(item) ? '#111' : '#aaa' }}
                >
                  {getComponentIcon(item)}
                  &nbsp;&nbsp; {item.type?.substring(0, 8)} &nbsp;
                </div>
              ),
              index: index,
              layout: item.layout,
              region: item.region,
              type: item.type,
              children: getTableColumns(item, index),
            });
          }
          if (item.type === 'map') {
            components.push({
              key: item.id,
              id: item.id,
              title: (
                <div
                  className='tree-node-item'
                  id={`component-tree-${item.id}`}
                  style={{ color: isVisible(item) ? '#111' : '#aaa' }}
                >
                  {getComponentIcon(item)}
                  &nbsp;&nbsp; {item.type} &nbsp;
                </div>
              ),
              index: index,
              layout: item.layout,
              region: item.region,
              type: item.type,
              children: getMapLayers(item, index),
            });
          }
        }
      });
      // assign components to regions
      regions.forEach((region: Dictionary, rIndex: number) => {
        components.forEach((component: Dictionary) => {
          if (
            region.layoutId === component.layout &&
            region.region === component.region
          ) {
            regions[rIndex] = component;
          }
        });
      });
    }
    return regions;
  };

  const getChildren = (item: Dictionary, index: number) => {
    if (item?.layers) {
      return getMapLayers(item, index);
    }
    if (item.type === 'table' && item?.properties) {
      return getTableColumns(item, index);
    }
    return [];
  };

  const getTreeData = () => {
    const obj: Dictionary = {};
    const array: Dictionary[] = [];
    const expanded: Dictionary | string[] = [];
    // get root components
    post.components.forEach((item: Dictionary, index: number) => {
      if (!item.layout) {
        obj[item.id] = {
          key: item.id,
          id: item.id,
          index: index,
          spaceComponent: item.spaceComponent,
          type: item.type,
          title: (
            <div
              className='tree-node-item'
              id={`component-tree-${item.id}`}
              style={{ color: item.hidden ? '#aaa' : '#111' }}
            >
              {getComponentIcon(item)}
              &nbsp;&nbsp;
              {item.name || item.type}&nbsp;&nbsp;
            </div>
          ),
          children: getChildren(item, index),
        };
      }
    });

    post.components.forEach((item: Dictionary) => {
      if (
        (item.type === 'layout' && obj[item.id]?.children) ||
        (item.type === 'card' && obj[item.id]?.children)
      ) {
        obj[item.id].children = getLayoutChildren(item); //getLayoutRegions(item)
      }
    });

    // transform back to array
    Object.keys(obj).forEach((key: string) => {
      array.push(obj[key]);
      expanded.push(key);
    });

    return array;
  };

  useEffect(() => {
    const treeData: Dictionary[] = getTreeData();
    setTreeData(treeData);
  }, [post.uuid, post.components]);

  return (
    <div className='component-menu'>
      <h2>Components</h2>
      {/*}
      <a
        className="editor-left-nav-toggle"
        title="Toggle Left Menu"
        href="#hide-drawer"
        onClick={(e) => {
          e.preventDefault();
          document.body.classList.toggle('hide-left-region');
          resizeMaps();
        }}
      >
        <DoubleLeftOutlined />
      </a>
      {*/}
      <Tree
        className='draggable-tree'
        draggable
        blockNode
        onRightClick={(e: Dictionary) => {
          e.event.preventDefault();
          setActiveNode(e.node);
          setMenuPosition([e.event.clientX, e.event.clientY]);
          setShowRightClickMenu(showRightClickMenu ? false : true);
        }}
        onSelect={(value, e) => {
          if (e.node.index || e.node.index === 0) {
            scrollToActiveComponent(`post-component-${e.node.index}`);
          }
          dispatch(
            actionConfigComponentIndexActive({
              value: e.node.index,
            })
          );
          if (e.node.type === 'layer') {
            dispatch(
              actionConfigActiveLayerEdit({
                value: e.node.layerIndex,
              })
            );
          } else {
            dispatch(
              actionConfigActiveLayerEdit({
                value: -1,
              })
            );
          }
          if (e.node.type === 'column') {
            dispatch(
              actionConfigActiveColumnEdit({
                value: e.node.columnIndex,
              })
            );
          } else {
            dispatch(
              actionConfigActiveColumnEdit({
                value: -1,
              })
            );
          }
          dispatch(
            actionConfigDrawerOpenEdit({
              value: true,
            })
          );
        }}
        onMouseEnter={(e: Dictionary) => {
          addActiveComponentClass(`component-${e.node.id}`);
        }}
        onMouseLeave={(e: Dictionary) => {
          removeActiveComponentClass(`component-${e.node.id}`);
        }}
        onDrop={(e: Dictionary) => {
          if (e.node.expanded && e.node.type !== 'table') {
            return false;
          }

          if (e.node.expanded && e.node.type === 'table') {
            return false;
          }

          if (e.node.expanded && e.node.type === 'map') {
            return false;
          }

          if (e.dragNode.type === 'region') {
            return false;
          }

          if (e.dragNode.type === 'layer') {
            return dispatch(
              actionPostArrayItemMove({
                componentIndex: e.dragNode.index,
                itemIndex: e.dragNode.layerIndex || 0,
                property: 'layers',
                fromIndex: e.dragNode.layerIndex || 0,
                toIndex: e.node.layerIndex,
              })
            );
          }

          if (e.node.type === 'column') {
            return dispatch(
              actionPostArrayItemMove({
                componentIndex: e.dragNode.index,
                itemIndex: e.dragNode.columnIndex,
                property: 'properties',
                fromIndex: e.dragNode.columnIndex,
                toIndex: e.node.columnIndex,
              })
            );
          }

          if (e.node.type !== 'region') {
            const currentIndex = e.dragNode.index;
            const newIndex = e.node.index;
            return dispatch(
              actionPostComponentOrderEdit({
                currentIndex: currentIndex,
                newIndex: newIndex,
                spaceComponent: false,
              })
            );
          }

          if (e.node.type === 'region') {
            if (e.dragNode.type === 'title') {
              return false;
            }
            return dispatch(
              actionPostComponentMove({
                index: e.dragNode.index,
                layoutId: e.node.layoutId,
                region: e.node.region,
                spaceComponent: e.node.spaceComponent,
              })
            );
          }
        }}
        treeData={treeData}
      />
      {showRightClickMenu && (
        <div
          onMouseLeave={() => {
            setShowRightClickMenu(false);
          }}
          className='treenode-options-menu'
          style={{
            position: 'fixed',
            top: `${menuPosition[1]}px`,
            left: `150px`,
            zIndex: 100000000,
          }}
        >
          <ul>
            <li>
              <a
                href='#hide'
                onClick={(e) => {
                  e.preventDefault();
                  if (activeNode.type === 'layer') {
                    return dispatch(
                      actionPostComponentSettingEdit({
                        componentIndex: activeNode.index,
                        itemIndex: activeNode.layerIndex,
                        property: 'visible',
                        parents: ['layers'],
                        value: post.components[activeNode.index].layers[
                          activeNode.layerIndex
                        ].visible
                          ? false
                          : true,
                      })
                    );
                  }
                  if (activeNode.type === 'column') {
                    return dispatch(
                      actionPostComponentSettingEdit({
                        componentIndex: activeNode.index,
                        itemIndex: activeNode.columnIndex,
                        property: 'visible',
                        parents: ['properties'],
                        value: post.components[activeNode.index].properties[
                          activeNode.columnIndex
                        ].visible
                          ? false
                          : true,
                      })
                    );
                  }
                  return dispatch(
                    actionPostComponentSettingEdit({
                      componentIndex: activeNode.index,
                      property: 'hidden',
                      value: post.components[activeNode.index].hidden
                        ? false
                        : true,
                    })
                  );
                }}
              >
                <EyeOutlined /> Hide / Show
              </a>
            </li>
            <li>
              {activeNode.type !== 'layout' && activeNode.type !== 'title' && (
                <a
                  href='#copy'
                  onClick={(e) => {
                    e.preventDefault();
                    if (activeNode.type === 'layer') {
                      const layer =
                        post.components[activeNode.index].layers[
                          activeNode.layerIndex
                        ];
                      return dispatch(
                        actionPostComponentCopy({
                          componentIndex: activeNode.index,
                          itemIndex: activeNode.layerIndex,
                          value: Object.assign({}, layer),
                        })
                      );
                    }
                    if (activeNode.type === 'column') {
                      const column =
                        post.components[activeNode.index].properties[
                          activeNode.columnIndex
                        ];
                      return dispatch(
                        actionPostComponentCopy({
                          componentIndex: activeNode.index,
                          itemIndex: activeNode.columnIndex,
                          value: Object.assign({}, column),
                        })
                      );
                    }
                    return dispatch(
                      actionPostComponentCopy({
                        componentIndex: activeNode.index,
                        itemIndex: null,
                        value: Object.assign(
                          {},
                          post.components[activeNode.index]
                        ),
                      })
                    );
                  }}
                >
                  <CopyOutlined />
                  &nbsp;&nbsp;Copy
                </a>
              )}
            </li>
            {activeNode.type !== 'title' && (
              <li>
                <a
                  href='#delete'
                  onClick={(e) => {
                    e.preventDefault();
                    // map layer
                    if (activeNode.type === 'layer') {
                      return dispatch(
                        actionPostArrayItemDelete({
                          componentIndex: activeNode.index,
                          itemIndex: activeNode.layerIndex,
                          property: 'layers',
                        })
                      );
                    }
                    // table column
                    if (activeNode.type === 'column') {
                      return dispatch(
                        actionPostArrayItemDelete({
                          componentIndex: activeNode.index,
                          itemIndex: activeNode.columnIndex,
                          property: 'properties',
                        })
                      );
                    }
                    // component
                    return dispatch(
                      actionPostComponentDelete({
                        index: activeNode.index,
                      })
                    );
                  }}
                >
                  <DeleteOutlined />
                  &nbsp;&nbsp;Delete
                </a>
              </li>
            )}
          </ul>
        </div>
      )}
      <AddComponentMenu />
    </div>
  );
};

export { ComponentMenu };
